import React from 'react'
import { Link as DomLink } from 'raviger'
import config from '../config.js'

const defaultClass = 'link'

export default function Link({ external, extraClassName, className, children, target, ...props }) {
  if (extraClassName && className)
    throw new Error('extraClassName and className are mutually exclusive')
  className = className === undefined ? defaultClass : className
  if (extraClassName) className += ' ' + extraClassName
  target = target === undefined ? '_blank' : target
  const addBasePath = isRelative(props.href) && !hasBasePath(props.href)
  const basePath = addBasePath ? config.basePath : undefined

  return external ? (
    <a href={props.href} className={className} target={target}>
      {children}
    </a>
  ) : (
    <DomLink className={className} {...props} basePath={basePath}>
      {children}
    </DomLink>
  )
}

function isRelative(href) {
  return href.startsWith('/')
}

function hasBasePath(href) {
  return href && href.startsWith(config.basePath)
}
