import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Container, Section, Button, Icon } from '@nike/epic-react-ui'

import { useAuthStore, selectUsername } from '@nike/aegis-auth-react'

import config from '../config.js'
import { Link, useConfirmModal, Modal, HelpText } from '../components/index.js'
import { notifyError } from '../notifications/actions.js'
import { createOrganization } from './actions.js'
import OrganizationForm from './OrganizationForm.js'

export default function CreateOrganizationPage() {
  const dispatch = useDispatch()
  const username = useAuthStore(selectUsername)
  const [submittedOrg, setOrg] = useState(null)
  const handleSubmit = useCallback(
    form => {
      return createOrganization(form)
        .then(result => {
          setOrg({
            name: result.name,
            groupName: result.adGroupName
          })
        })
        .catch(error => {
          console.error('error submitting org', error)
          dispatch(notifyError({ message: error.message }))
        })
    },
    [dispatch, setOrg]
  )
  const clearOrg = useCallback(() => setOrg(null), [setOrg])
  const { handleConfirm, ...modalProps } = useConfirmModal(handleSubmit)

  return (
    <Container>
      <Section>
        {submittedOrg ? (
          <div>
            <SuccessMessage org={submittedOrg} />

            <Button onClick={clearOrg}>Create another Organization</Button>
          </div>
        ) : (
          <>
            <OrganizationForm isNew username={username} onSubmit={handleConfirm} />
            <Modal {...modalProps} title='Create Organization' acceptButtonLabel='Continue'>
              <p>
                Taking this action will provision resources on github.com, Okta, and Active
                Directory. Do you wish to continue?
              </p>
            </Modal>
          </>
        )}
      </Section>
    </Container>
  )
}

function SuccessMessage({ org }) {
  const githubUrl = `https://github.com/${org.name}`
  return (
    <>
      <HelpText>
        Successfully created{' '}
        <Link href={githubUrl} external>
          {githubUrl}
        </Link>
      </HelpText>
      <HelpText>Manage the Group Membership for {org.groupName} in ID Locker.</HelpText>

      <p style={{ padding: '8px 0' }}>
        <Icon type='slack' /> Please{' '}
        <Link external href={config.slackChannelComplete}>
          Join
        </Link>{' '}
        the {config.slackChannelCompleteName} slack channel to receive important notifications
        regarding GitHub and Migrations{' '}
      </p>
    </>
  )
}
