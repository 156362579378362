import React from 'react'
import { Container } from '@nike/epic-react-ui'

import config from '../config.js'
import { Link } from '../components/index.js'
import OnboardingIcon from '../organizations/OnboardingIcon.js'

export default function HomePage() {
  if (config.NOT_READY) return <NotReady />
  return (
    <Container>
      <div className='home-panel'>
        <span className='home-grid-step'>1</span>
        <span className='home-grid-body'>
          <p className='home-grid-title'>Request Access</p>
          <p>Fill out this form to request creation of a new GitHub Organization.</p>
          <Link href='/organizations/new' className='home-grid-link'>
            New Organization request
          </Link>
        </span>
        <div className='home-grid-icon'>
          <Link href='/organizations/new'>
            <OnboardingIcon />
          </Link>
        </div>
      </div>
      <div className='home-panel'>
        <span className='home-grid-step'>2</span>
        <span className='home-grid-body'>
          <p className='home-grid-title'>Manage Requests</p>
          <p>Manage migration options for organizations you previously created.</p>
          <Link href='/organizations' className='home-grid-link'>
            My Organizations
          </Link>
        </span>
        <div className='home-grid-icon'>
          <Link href='/organizations'>
            <OnboardingIcon />
          </Link>
        </div>
      </div>
    </Container>
  )
}

function NotReady() {
  return <p>This onboarding portal will open soon, please check back later.</p>
}
