import React from 'react'

import { useRoutes } from 'raviger'
import { Button, NavBar } from '@nike/epic-react-ui'
import { LoginRequired } from '@nike/aegis-auth-react'

import client from '../auth/client.js'
import config from '../config.js'
import routes from './routes.js'
import Toasts from '../notifications/Toasts.js'
import NotFoundPage from './NotFoundPage.js'
import OfflineBanner from '../offline/OfflineBanner.js'
import { Link } from '../components/index.js'
import DisabledPage from './DisabledPage.js'

const navBarLinks = []
const slackUrl = { url: config.slackChannel }

export default function App() {
  const basePath = config.basePath

  const routeResult = useRoutes(routes, { basePath }) || <NotFoundPage />

  if (config.disabled) return <DisabledPage />

  return (
    <div>
      <OfflineBanner />
      <div>
        <NavBar
          name={config.appFullName}
          logo='/logo.png'
          rootPath='/'
          routes={navBarLinks}
          RouterLink={Link}
          slack={slackUrl}
        />
        <LoginRequired client={client} Button={Button}>
          <div style={{ margin: '1rem' }}>{routeResult}</div>
        </LoginRequired>
      </div>
      <Toasts />
    </div>
  )
}
