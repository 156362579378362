import React, { useState } from 'react'
import { Select } from '@nike/epic-form-hook-ext'
import { useDebouncedCallback } from 'use-debounce'

import { searchUsers } from './actions.js'

export default function UserSearchSelect({ placeholder, label, name }) {
  const [users, setUsers] = useState([])

  const handleInputChange = useDebouncedCallback(val => {
    if (!val || val.length < 2) return
    // Do not return the promise or react-select will crash
    searchUsers(val).then(newUsers => setUsers(newUsers.map(asOptions)))
  }, 250).callback

  return (
    <Select
      name={name}
      label={label}
      placeholder={placeholder || 'Search Users'}
      onInputChange={handleInputChange}
      options={users}
      // The default filter will remove options that don't match the input
      // but the search is more tolerant than the input field
      // without removing the default filter "name, user" will show nothing
      // since the result "user.name@nike.com" does not contain the input
      filterOption={null}
    />
  )
}

function asOptions(user) {
  return { label: user.email, value: user.id }
}
