import { produce } from 'immer'

import { notifyOffline } from './actions.js'

const defaultState = {
  offline: false
}

const offline = produce((state, action) => {
  switch (action.type) {
    case notifyOffline.type:
      state.offline = action.payload.offline
      break
    default:
      break
  }
}, defaultState)

export default offline

export const isOffline = state => state.offline.offline
