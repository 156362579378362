import React, { useState, useCallback, useMemo, useRef } from 'react'
import { Button, Modal as EpicModal } from '@nike/epic-react-ui'

export default function Modal({
  showModal,
  onClose,
  title,
  onCancel,
  cancelButtonLabel = 'Cancel',
  onAccept,
  acceptButtonLabel = 'OK',
  children,
  ...props
}) {
  return (
    <EpicModal
      className='epic-modal'
      onClose={onClose}
      closeButton
      swoosh
      show={showModal}
      {...props}
    >
      <EpicModal.Title>
        <h2>{title}</h2>
      </EpicModal.Title>
      <EpicModal.Content>{children}</EpicModal.Content>
      <EpicModal.Actions>
        {onCancel ? (
          <Button inverse onClick={onCancel}>
            {cancelButtonLabel}
          </Button>
        ) : null}
        {onAccept ? <Button onClick={onAccept}>{acceptButtonLabel}</Button> : null}
      </EpicModal.Actions>
    </EpicModal>
  )
}

// Needs static value to avoid memo-thrashing
const defaultProps = {}

export function useModal({ onAccept, onClose, onCancel } = defaultProps) {
  const [showModal, setShowModal] = useState(false)
  const handleClose = useCallback(
    e => {
      if (e) e.preventDefault()
      setShowModal(false)
      if (onClose) onClose(e)
    },
    [onClose]
  )
  const handleCancel = useCallback(
    e => {
      if (e) e.preventDefault()
      setShowModal(false)
      if (onCancel) onCancel(e)
    },
    [onCancel]
  )
  const handleAccept = useCallback(
    e => {
      if (e) e.preventDefault()
      setShowModal(false)
      if (onAccept) onAccept(e)
    },
    [onAccept]
  )

  return useMemo(() => {
    return {
      showModal,
      setShowModal,
      onCancel: handleCancel,
      onClose: handleClose,
      onAccept: handleAccept
    }
  }, [handleAccept, handleCancel, handleClose, showModal, setShowModal])
}

export function useConfirmModal(onConfirm) {
  const promise = useRef(null)
  const [showModal, setShowModal] = useState(false)
  const handleAccept = useCallback(() => {
    if (!promise.current) return
    promise.current.resolve(true)
  }, [])
  const handleCancel = useCallback(() => {
    if (!promise.current) return
    promise.current.resolve(false)
  }, [])

  const handleConfirm = useCallback(
    data => {
      setShowModal(true)
      return new Promise((resolve, reject) => {
        promise.current = { resolve, reject }
      }).then(accepted => {
        setShowModal(false)
        promise.current = null
        if (accepted) return onConfirm(data)
      })
    },
    [onConfirm]
  )
  return useMemo(
    () => ({
      showModal,
      setShowModal,
      onClose: handleCancel,
      onCancel: handleCancel,
      onAccept: handleAccept,
      handleConfirm
    }),
    [showModal, handleConfirm, handleAccept, handleCancel]
  )
}
