import React from 'react'
import { Container } from '@nike/epic-react-ui'

const style = { marginTop: '5rem' }

export default function Disabled() {
  return (
    <Container>
      <div style={style}>
        The GOAT is currently unavailable while we sort some things out. Check out{' '}
        <a
          href='https://confluence.nike.com/display/BAAT/How+to+request+a+Github+Enterprise+Cloud+Organization'
          rel='noopener noreferrer nofollow'
        >
          Confluence
        </a>{' '}
        for more information.
      </div>
    </Container>
  )
}
