import merge from 'deepmerge'

const isInConsole = window.location.href.includes('platforms.nike.com/')

const basePath = isInConsole ? '/viceroy' : ''

const NOT_READY = false

const base = {
  NOT_READY,
  disabled: true,
  isProd: false,
  appName: 'GOAT',
  appFullName: 'Github Onboarding Access Tool',
  description: 'GitHub Cloud Organization manager',
  basePath,
  slackChannel: 'https://nikedigital.slack.com/archives/C01AWTSFV3K',
  slackChannelComplete: 'https://nikedigital.slack.com/archives/C01KQJ2A2SD',
  slackChannelCompleteName: '#ghec-org-admins',
  oAuth: {
    clientId: 'nike.ep.viceroy',
    redirectUri: isInConsole ? window.location.origin + '/viceroy' : window.location.origin + '/'
  }
}
const dev = merge(base, {
  apiUrl: 'https://api-viceroy-dev.baat-tools-test.nikecloud.com/v1/',
  aegisApi: 'https://api.aegis.nikecloud.com/v1/qa',
  oAuth: {
    authorization: 'https://api.aegis.nikecloud.com/v1/qa/authorize',
    token: 'https://api.aegis.nikecloud.com/v1/qa/token'
  }
})

const prod = merge(base, {
  isProd: true,
  apiUrl: 'https://api-viceroy.baat-tools-prod.nikecloud.com/v1',
  aegisApi: 'https://api.aegis.nikecloud.com/v1/prod',
  oAuth: {
    authorization: 'https://api.aegis.nikecloud.com/v1/prod/authorize',
    token: 'https://api.aegis.nikecloud.com/v1/prod/token'
  }
})

const config = window.location.origin.includes('goat.baat-tools-prod.nikecloud.com') ? prod : dev

export default config
