import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { ToggleSwitch as EpicToggle } from '@nike/epic-react-ui'

// This code is literally a copy-paste from epic-hook-ext
// It works here, it doesn't work from the library
// TODO: figure out the library issue and remove this module

export default function ToggleSwitch({ options, ...props }) {
  const { name } = props
  const { errors, register, watch, setValue } = useFormContext()
  const error = errors[name]
  useEffect(() => {
    register({ name })
  }, [register, name])
  const value = watch(props.name)
  return (
    <EpicToggle
      {...props}
      hasErrors={!!error}
      errorMessage={error}
      value={value}
      options={options}
      onChange={newValue => {
        setValue(name, newValue, true)
      }}
      blurInputOnSelect
    />
  )
}
