import React, { useMemo } from 'react'

import { Table, Button } from '@nike/epic-react-ui'
import { Link } from '../components/index.js'

const columns = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Primary Owner',
    accessor: 'primaryOwnerUsername'
  },
  {
    Header: 'Secondary Owner',
    accessor: 'secondaryOwnerUsername'
  },
  {
    Header: 'Actions',
    accessor: 'actions'
  }
]

export default function OrganizationsList({ organizations }) {
  const rows = useMemo(
    () =>
      organizations.map(org => ({
        ...org,
        actions: <ActionCell org={org} />
      })),
    [organizations]
  )
  return <Table columns={columns} data={rows} staticTable />
}

function ActionCell({ org }) {
  return (
    <Link href={`/organizations/${encodeURIComponent(org.name)}`}>
      <Button>Edit</Button>
    </Link>
  )
}
