import { AegisClient } from '@nike/aegis-auth-react'

import config from '../config.js'
import { setGlobalHeader } from '../util/http.js'

const oAuth = new AegisClient({ qa: !config.isProd, ...config.oAuth })

function handleLogin({ accessToken }) {
  setGlobalHeader('Authorization', 'Bearer ' + accessToken.accessToken)
}

oAuth.on('login:success', handleLogin)
oAuth.on('renew:access:success', handleLogin)

export default oAuth
