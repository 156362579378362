import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Section } from '@nike/epic-react-ui'
import { useAsyncStatus } from '@nike/redux-action-utils'

import { Error, Spinner } from '../components/index.js'
import { notifyError } from '../notifications/actions.js'
import { updateOrganization, getOrganization } from './actions.js'
import { selectEditOrganization } from './reducer.js'

import OrganizationForm from './OrganizationForm.js'

export default function EditOrganizationPage({ id }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getOrganization(id))
  }, [dispatch, id])

  const organization = useSelector(selectEditOrganization)
  const [isLoading, error] = useAsyncStatus(getOrganization)

  const handleSubmit = useCallback(
    form => {
      return dispatch(updateOrganization({ ...organization, ...form })).catch(error => {
        console.error('error saving org', error)
        dispatch(notifyError({ message: error.message }))
      })
    },
    [dispatch, organization]
  )

  if (error) return <Error error={error} />
  if (isLoading) return <Spinner large />

  return (
    <Container>
      <Section>
        <OrganizationForm initialValues={organization} onSubmit={handleSubmit} />
      </Section>
    </Container>
  )
}
