import qs from 'qs'
import join from 'url-join'

import { useAuthStore, selectUsername } from '@nike/aegis-auth-react'

import { asyncAction } from '@nike/redux-action-utils'

import { notifyError } from '../notifications/actions.js'
import http from '../util/http.js'

import config from '../config.js'

const userUrl = join.bind(join, config.aegisApi, 'users')
const orgUrl = join.bind(join, config.apiUrl, 'organizations')

// export const setProjectCreateType = createAction('PROJECT_CREATE_SET_TYPE')

export async function isOrganizationNameAvailable(name) {
  if (!name) return false
  // API returns empty array if name is not in use
  const organizations = await http.get(orgUrl() + `?name=${name}`)
  return !organizations.items.length
}

export async function searchUsers(query) {
  if (!query || query <= 2) return []
  try {
    const response = await http.get(userUrl() + `?${qs.stringify({ startsWith: query })}`)
    return response.items
  } catch (e) {
    console.error('user search error', e)
    return []
  }
}

export async function createOrganization(organization) {
  return http.post(orgUrl(), organization)
}

export const getOrganization = asyncAction({
  baseName: 'ORGANIZATION_GET',
  action: action => name => async (dispatch, getState) => {
    dispatch(action.start(name))
    try {
      const response = await http.get(orgUrl(name))
      dispatch(action.success(response))
    } catch (e) {
      dispatch(notifyError({ message: e.message }))
      dispatch(action.error(e))
    }
  }
})

export const updateOrganization = asyncAction({
  baseName: 'ORGANIZATION_UPDATE',
  action: action => org => async (dispatch, getState) => {
    dispatch(action.start(org.name))
    try {
      const response = await http.put(orgUrl(org.name), org)
      dispatch(action.success(response))
    } catch (e) {
      dispatch(notifyError({ message: e.message }))
      dispatch(action.error(e))
    }
  }
})

export const getUserOrganizations = asyncAction({
  baseName: 'ORGANIZATIONS_USER_GET',
  action: action => () => async dispatch => {
    const username = selectUsername(useAuthStore.getState())
    dispatch(action.start(username))
    try {
      const response = await http.get(
        orgUrl() + qs.stringify({ username }, { addQueryPrefix: true })
      )
      dispatch(action.success(response.items))
    } catch (e) {
      dispatch(notifyError({ message: e.message }))
      dispatch(action.error(e))
    }
  }
})
