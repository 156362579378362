import React, { useState, useEffect, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { useSelector, useDispatch } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { IconButton } from '@nike/epic-react-ui'

import { removeNotification } from '../notifications/actions.js'
import { selectNotifications } from '../notifications/reducer.js'

const toastRoot = document.getElementById('toast-root')

const formatNotifcation = message => {
  if (message instanceof Error) {
    return message.message
  }
  if (typeof message === 'object') {
    throw new Error('unable to format object as notification')
  }

  return message
}

const timeout = { enter: 400, exit: 400 }

export default function Toasts() {
  const dispatch = useDispatch()
  const [element] = useState(document.createElement('div'))
  const toasts = useSelector(selectNotifications)
  const handleRemove = useCallback(id => dispatch(removeNotification(id)), [dispatch])

  useEffect(() => {
    toastRoot.appendChild(element)
    return () => toastRoot.removeChild(element)
  }, [element])
  return createPortal(
    <div className='alertify-logs bottom right'>
      <TransitionGroup>
        {toasts.map(toast => (
          <CSSTransition key={toast.id} classNames='alertify-log' timeout={timeout}>
            <div
              className={`${toast.type} alertify-log`}
              onClick={toast.closeOnClick ? () => handleRemove(toast.id) : undefined}
            >
              {formatNotifcation(toast.message)}

              <IconButton type='close-x' bare iconFontSize='20px' />
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>,
    element
  )
}
