import React, { useEffect, useCallback } from 'react'
import { Icon, IconButton, TextInput as EpicInput, Label } from '@nike/epic-react-ui'
import { useFormContext } from '@nike/epic-form-hook-ext'
import classnames from 'classnames'

const defaultItems = []

export default function InputList({
  title,
  buttonLabel,
  error,
  name,
  placeholder,
  className,
  disabled
}) {
  const { register, watch, setValue } = useFormContext()
  className = classnames('input-list', className)

  const items = watch(name) || defaultItems
  useEffect(() => {
    register({ name })
  }, [register, name])

  // Form handlers
  const onAdd = useCallback(() => {
    setValue(name, [...items, ''], { shouldValidate: true, shouldDirty: true })
  }, [items, setValue, name])
  const onRemove = useCallback(index => setValue(name, removeIndex(items, index)), [
    name,
    setValue,
    items
  ])
  const onUpdate = (index, value) => {
    const updatedItems = [...items]
    updatedItems[index] = value
    setValue(name, updatedItems, { shouldValidate: true, shouldDirty: true })
  }
  return (
    <div className={className}>
      <Label label={title} />
      {!disabled && (
        <div className='input-list-add-button'>
          <IconButton type='add' small iconFontSize='8' onClick={onAdd}>
            Add URI
          </IconButton>
        </div>
      )}

      <div className='field-array-error'>{error}</div>
      <ul>
        {items.map((item, index) => {
          return (
            <li key={`${items.length}.${index}`}>
              <div className='input-list-item'>
                <EpicInput
                  placeholder={placeholder}
                  name={`${name}[${index}]`}
                  value={items[index]}
                  onChange={e => onUpdate(index, e.target.value)}
                  disabled={disabled}
                />
                {!disabled && <DeleteRowButton remove={onRemove} index={index} />}
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

function DeleteRowButton({ remove, index }) {
  return (
    <button
      onClick={() => {
        remove(index)
      }}
    >
      <Icon type='delete' fontSize='24px' />
    </button>
  )
}

function removeIndex(array, index) {
  array.splice(index, 1)
  return array
}
