import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'

import Root from './root/Root.js'
import configureStore from './root/store.js'
import reportWebVitals from './reportWebVitals.js'
import * as serviceWorker from './serviceWorker.js'
import { watchOnlineState } from './offline/actions.js'
import { enableMapSet } from 'immer'

import '@nike/epic-react-ui/dist/styles/main.css'
import './index.css'

enableMapSet()

export const store = configureStore()

watchOnlineState(store)

ReactDOM.render(<Root store={store} />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
