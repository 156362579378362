import { combineReducers } from 'redux'
import { parseErrorMiddleware, requestReducer } from '@nike/redux-action-utils'

import offline from '../offline/reducer.js'
import notifications from '../notifications/reducer.js'
import organizations from '../organizations/reducer.js'

const rootReducer = combineReducers({
  // auth,
  parseErrorMiddleware,
  offline,
  notifications,
  request: requestReducer,
  organizations
})

export default rootReducer
