import React from 'react'

import HomePage from '../home/HomePage.js'

import UserOrganizationsPage from '../organizations/UserOrganizationsPage.js'
import CreateOrganizationPage from '../organizations/CreateOrganizationPage.js'
import EditOrganizationPage from '../organizations/EditOrganizationPage.js'

const routes = {
  '/': () => <HomePage />,
  '/organizations': () => <UserOrganizationsPage />,
  '/organizations/new': () => <CreateOrganizationPage />,
  '/organizations/:id': ({ id }) => <EditOrganizationPage id={id} />
}

export default routes
