import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Spinner } from '@nike/epic-react-ui'
import { useAsyncStatus } from '@nike/redux-action-utils'

import { Link, Error } from '../components/index.js'
import { getUserOrganizations } from './actions.js'
import { selectOrganizations } from './reducer.js'

import OrganizationList from './OrganizationList.js'

export default function UserOrganizationsPage() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getUserOrganizations())
    // This is an onload, it has no dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const userOrganizations = useSelector(selectOrganizations)
  const [isLoading, error] = useAsyncStatus(getUserOrganizations)

  if (error) return <Error error={error} />
  if (isLoading) return <Spinner />

  return (
    <Container>
      {userOrganizations.length ? (
        <OrganizationList organizations={userOrganizations} />
      ) : (
        <p>
          You have not created any organizations yet.{' '}
          <Link href='/organizations/new'>Create one here</Link>
        </p>
      )}
    </Container>
  )
}
