import { produce } from 'immer'

import { addNotification, removeNotification, clearNotifications } from './actions.js'

const defaultState = {
  notifications: new Map()
}

const notifications = produce((state, action) => {
  switch (action.type) {
    case addNotification.type:
      state.notifications.set(action.payload.id, action.payload)
      if (state.notifications.size > 2) {
        state.notifications = new Map([...state.notifications.entries()].slice(-2))
      }
      return state
    case removeNotification.type:
      state.notifications.delete(action.payload)
      return state
    case clearNotifications.type:
      state.notifications = defaultState.notifications
      return state
    default:
      return state
  }
}, defaultState)

export default notifications

export const selectNotifications = state => [...state.notifications.notifications.values()]
