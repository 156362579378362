import produce from 'immer'

import { getOrganization, getUserOrganizations, updateOrganization } from './actions.js'

const INITAL_STATE = {
  editOrganization: null,
  organizations: []
}

export default produce((draft, action) => {
  // immer defaults to returning the unmodified state
  // eslint-disable-next-line default-case
  switch (action.type) {
    case getOrganization.event.success:
    case updateOrganization.event.success:
      draft.editOrganization = action.payload
      return
    case getUserOrganizations.event.success:
      draft.organizations = action.payload
      return
  }
}, INITAL_STATE)

export const selectEditOrganization = state => state.organizations.editOrganization
export const selectOrganizations = state => state.organizations.organizations
