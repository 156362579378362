import React from 'react'
import { Input, ControlledForm, useForm, SubmitButton } from '@nike/epic-form-hook-ext'
// import { Label } from '@nike/epic-react-ui'

import config from '../config.js'
import UserSearchSelect from './UserSearchSelect.js'
import { isOrganizationNameAvailable } from './actions.js'
import { ToggleSwitch, InputList, HelpText, Link } from '../components/index.js'

const nikeDevFormat = /nikebeta-[a-zA-Z0-9-]{2,31}$/
const nikeProdFormat = /nike-[a-zA-Z0-9-]{2,35}$/

const converseDevFormat = /conversebeta-[a-zA-Z0-9-]{2,27}$/
const converseProdFormat = /converse-[a-zA-Z0-9-]{2,31}$/

const nameFormats = config.isProd
  ? [nikeProdFormat, converseProdFormat]
  : [nikeDevFormat, converseDevFormat]

const migrationTypeOptions = [
  // { label: 'Bulk Migration', value: 'bulk' },
  { label: 'Self Migration', value: 'self' },
  { label: 'None', value: 'none' }
]
const migrationPhaseOptions = [
  { label: 'Early', value: 'a' },
  { label: 'Middle', value: 'b' },
  { label: 'Late', value: 'c' }
]
const migrationBulkOptions = [{ label: 'Bulk Migration', value: 'bulk' }]

export default function OrganizationForm({ onSubmit, initialValues = {}, isNew, username }) {
  const context = useForm({
    resolver: validateForm,
    context: { isNew },
    // Needs onBlur for async validation of project name
    mode: isNew ? 'onBlur' : undefined,
    defaultValues: {
      migrationType: 'self',
      originOrganizations: [],
      primaryOwnerUsername: username,
      ...initialValues
    }
  })

  const isInitialBulk = !isNew && initialValues?.migrationType === 'bulk'
  const migrationType = context.watch('migrationType')
  const needsMigrationPhase = migrationType === 'bulk' && !isInitialBulk
  const needsOrigins = migrationType !== 'none'

  return (
    <ControlledForm onSubmit={onSubmit} context={context} className='api-edit-form'>
      <Input
        name='name'
        label='New GitHub.com Organization Name'
        placeholder='must be in format "nike-*" or "converse-*"'
        readOnly={!isNew}
      />

      {isInitialBulk ? (
        <>
          <ToggleSwitch
            label='Migration Type'
            name='migrationType'
            options={migrationBulkOptions}
            disabled
          />
          <Input
            label='Migration Phase'
            value={migrationPhaseOptions.find(m => m.value === initialValues.migrationPhase)?.label}
          />
        </>
      ) : (
        <ToggleSwitch label='Migration Type' name='migrationType' options={migrationTypeOptions} />
      )}

      <HelpText>
        See our{' '}
        <Link external href='https://confluence.nike.com/display/BAAT/Github+Onboarding+Process'>
          docs for details
        </Link>
      </HelpText>
      {needsMigrationPhase && (
        <>
          <ToggleSwitch
            label='Desired Migration Phase (March 19th - May 31st)'
            name='migrationPhase'
            options={migrationPhaseOptions}
            disabled={!isNew}
          />
        </>
      )}
      {/* <Label>Active Directory Group Owners</Label> */}
      <Input name='primaryOwnerUsername' label='Primary AD Group Owner' readOnly />
      {isNew ? (
        <UserSearchSelect
          name='secondaryOwnerUsername'
          label='Secondary AD Group Owner (Required, Must be FTE)'
        />
      ) : (
        <Input
          name='secondaryOwnerUsername'
          label='Secondary AD Group Owner (Required, Must be FTE)'
          readOnly
        />
      )}

      {needsOrigins && (
        <>
          <InputList
            title='BitBucket Projects and GitHub Enterprise Organizations that will be migrated'
            name='originOrganizations'
            placeholder='Bitbucket Project or GitHub Organization'
            className='environment'
            disabled={isInitialBulk}
          />
          <HelpText>
            You may include any number of Organizations that are being migrated from. They must be
            the full URI to the Nike BitBucket Project or Nike GitHub Enterprise Organization, e.g.
            &quot;https://github.nike.com/sprockets&quot; or
            &quot;https://bitbucket.nike.com/projects/AAA&quot;. Origin URLs must be unique;
            multiple Organizations cannot share an Origin.
          </HelpText>
        </>
      )}
      {!isInitialBulk && <SubmitButton label='Submit' />}
    </ControlledForm>
  )
}

export async function validateForm(values, { isNew }) {
  const errors = {}

  if (!values.name) {
    errors.name = 'Organization Name is required'
  } else if (!nameFormats.some(f => f.test(values.name))) {
    errors.name =
      'Organization Name must match "nike-*" or "converse-*" using only alphanumeric + dashes'
  } else if (isNew) {
    const isAvailable = await isOrganizationNameAvailable(values.name)
    if (!isAvailable) {
      errors.name = 'name is not available'
    }
  }

  if (!values.secondaryOwnerUsername) errors.secondaryOwnerUsername = 'Secondary Owner is required'
  else if (values.primaryOwnerUsername === values.secondaryOwnerUsername) {
    errors.secondaryOwnerUsername = 'Secondary Owner must be different than Primary Owner'
  }
  return {
    values,
    errors
  }
}
